var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "详情",
        visible: _vm.showDialog,
        width: "1000px",
        top: "15vh",
        "close-on-click-modal": false,
        modal: false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "80px",
                disabled: "",
                "label-suffix": ":",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品名称", prop: "" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.goods_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_name", $$v)
                      },
                      expression: "form.goods_name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "规格", prop: "" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.spec,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "spec", $$v)
                      },
                      expression: "form.spec",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "包装系数", prop: "" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.goods_convert,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "goods_convert", $$v)
                      },
                      expression: "form.goods_convert",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            { attrs: { stripe: "", data: _vm.list } },
            _vm._l(_vm.columns, function (item, tableIndex) {
              return _c("el-table-column", {
                key: tableIndex,
                attrs: {
                  prop: item.prop,
                  label: item.label,
                  width: item.width,
                  align: "center",
                },
              })
            }),
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }