<template>
  <el-dialog
    title="详情"
    :visible.sync="showDialog"
    width="1000px"
    top="15vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="80px"
        disabled
        label-suffix=":"
      >
        <el-form-item label="商品名称" prop="">
          <el-input v-model="form.goods_name"></el-input>
        </el-form-item>
        <el-form-item label="规格" prop="">
          <el-input v-model="form.spec"></el-input>
        </el-form-item>
        <el-form-item label="包装系数" prop="">
          <el-input v-model="form.goods_convert"></el-input>
        </el-form-item>
      </el-form>
      <el-table stripe :data="list">
        <!-- 序号 -->
        <el-table-column
          v-for="(item, tableIndex) in columns"
          :key="tableIndex"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          align="center"
        ></el-table-column>
      </el-table>
      <div class="dialog-footer">
        <el-button @click="showDialog = false">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { postAction } from '@/api/Employee'
  export default {
    name: 'Check',
    components: {},
    data() {
      return {
        loading: false,
        id: null,
        showDialog: false,
        form: {},
        list: [],
        columns: [
          {
            label: '仓库',
            prop: 'depot_name',
            width: '',
          },
          {
            label: '库存数量',
            prop: 'balance_count_text',
            width: '',
          },
          {
            label: '参考货值金额',
            prop: 'amount',
            width: '',
          },
        ],
      }
    },
    computed: {
      title() {
        return '标题'
      },
    },
    watch: {
      showDialog(v) {
        if (v) {
          this.fetchData()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data } = await postAction(
          '/depotAdmin/depot-stock/summary-depot-car-detail',
          { goods_id: this.id }
        )
        console.log('data', data)
        data = data.rows
        this.form = data.info
        this.list = data.list
        this.loading = false
        // /depotAdmin/depot-stock/summary-depot-car-detail
        // 库车统计 -- 详情
      },
    },
  }
</script>
<style lang="scss" scoped></style>
