var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            model: _vm.form,
            inline: "",
            "label-position": "right",
            "label-width": "120px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "depot_type" } },
            [
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "仓库类型" },
                  model: {
                    value: _vm.form.depot_type,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "depot_type", $$v)
                    },
                    expression: "form.depot_type",
                  },
                },
                [
                  _c("el-option", {
                    attrs: { label: "仓库+车辆", value: "0" },
                  }),
                  _c("el-option", { attrs: { label: "仓库", value: "1" } }),
                  _c("el-option", { attrs: { label: "车辆", value: "2" } }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("class-select", {
                on: { "class-select-change": _vm.classChange },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("brand-select", {
                on: { "brand-select-change": _vm.brandChange },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("goods-search", {
                attrs: { "is-table": false },
                on: {
                  "input-goods": _vm.goodsSelect,
                  "select-search-goods": _vm.addRows,
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleQuery } },
                [_vm._v("查询")]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "", prop: "" } },
            [
              _c("el-button", { on: { click: _vm.handleExport } }, [
                _vm._v("导出"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              type: "selection",
              width: "50",
              fixed: "",
            },
          }),
          _vm._l(_vm.columns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: item.width,
                align: "center",
                fixed: tableIndex < 6 ? true : false,
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        item.label == "合计库存"
                          ? _c("div", { staticStyle: { color: "#f60" } }, [
                              _vm._v(" " + _vm._s(row[item.prop]) + " "),
                            ])
                          : _c("div", [
                              _vm._v(" " + _vm._s(row[item.prop]) + " "),
                            ]),
                      ]
                    },
                  },
                ],
                null,
                true
              ),
            })
          }),
        ],
        2
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.form.pageNo,
          layout: _vm.layout,
          "page-size": _vm.form.pageSize,
          total: _vm.total,
          background: "",
        },
        on: {
          "current-change": _vm.handleCurrentChange,
          "size-change": _vm.handleSizeChange,
        },
      }),
      _c("check", { ref: "check" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }