<template>
  <!-- NAME[epic=库管] 商品车库统计 -->
  <div class="orderTest-container">
    <el-form
      ref="form"
      :model="form"
      inline
      label-position="right"
      label-width="120px"
    >
      <el-form-item label="" prop="depot_type">
        <el-select v-model="form.depot_type" clearable placeholder="仓库类型">
          <!-- 0仓库+车辆 1仓库 2车辆 -->
          <el-option label="仓库+车辆" value="0"></el-option>
          <el-option label="仓库" value="1"></el-option>
          <el-option label="车辆" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <class-select @class-select-change="classChange"></class-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <brand-select @brand-select-change="brandChange"></brand-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <goods-search
          :is-table="false"
          @input-goods="goodsSelect"
          @select-search-goods="addRows"
        ></goods-search>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button type="primary" @click="handleQuery">查询</el-button>
      </el-form-item>
      <el-form-item label="" prop="">
        <el-button @click="handleExport">导出</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" stripe :data="list">
      <!-- 序号 -->
      <!-- <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column> -->
      <!-- 选择框 -->
      <el-table-column
        align="center"
        type="selection"
        width="50"
        fixed
      ></el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in columns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        :width="item.width"
        align="center"
        :fixed="tableIndex < 6 ? true : false"
      >
        <template #default="{ row }">
          <div v-if="item.label == '合计库存'" style="color: #f60">
            {{ row[item.prop] }}
          </div>
          <div v-else>
            {{ row[item.prop] }}
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" fixed="right" width="100" align="center">
        <template #default="{ row }">
          <el-button type="text" @click="handleCheck(row)">查看详情</el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      :current-page="form.pageNo"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    ></el-pagination>
    <check ref="check"></check>
  </div>
</template>
<script>
  import _ from 'lodash'
  import classSelect from '@/baseComponents/classSelect'
  import brandSelect from '@/baseComponents/brandSelect'
  import goodsSearch from '@/baseComponents/goodsSearch'
  import { downloadFile, postAction } from '@/api/Employee'
  import Check from './components/check'
  export default {
    name: 'CarDepotSummary',
    components: { classSelect, brandSelect, goodsSearch, Check },
    data() {
      return {
        total: 0,
        list: [],
        loading: false,
        layout: 'total, sizes, prev, pager, next, jumper',
        form: {
          pageSize: 10,
          pageNo: 1,
        },
        // checkList: [
        //   '商品编码',
        //   '商品名称',
        //   '规格',
        //   '包装系数',
        //   '仓库库存',
        //   '车辆库存',
        //   '合计库存',
        //   '合计货值金额',
        // ],
        columns: [
          // {
          //   order: 1,
          //   label: '商品编码',
          //   prop: '',
          // },
          // {
          //   order: 1,
          //   label: '商品名称',
          //   prop: 'goods_name',
          //   width: '220',
          // },
          // {
          //   order: 2,
          //   label: '规格',
          //   prop: 'specs',
          //   width: '',
          // },
          // {
          //   order: 3,
          //   label: '包装系数',
          //   prop: 'goods_convert',
          //   width: '',
          // },
          // {
          //   order: 3.1,
          //   label: '合计库存',
          //   prop: '',
          //   width: '',
          // },
          // {
          //   order: 3.2,
          //   label: '合计货值金额',
          //   prop: '',
          //   width: '',
          // },
          // {
          //   order: 3.3,
          //   label: '总仓',
          //   prop: '',
          //   width: '',
          // },
          // {
          //   order: 4,
          //   label: '仓库库存',
          //   prop: 'depot_num_text',
          //   width: '',
          // },
          // {
          //   order: 5,
          //   label: '车辆库存',
          //   prop: 'car_num_text',
          //   width: '',
          // },
          // {
          //   order: 6,
          //   label: '合计库存',
          //   prop: 'zong_num_text',
          //   width: '',
          // },
          // {
          //   order: 7,
          //   label: '合计货值金额',
          //   prop: 'amount',
          //   width: '',
          // },
        ],
      }
    },
    computed: {
      // finallyColumns() {
      //   let finallyArray = []
      //   this.checkList.forEach((checkItem) => {
      //     finallyArray.push(
      //       this.columns.filter((item) => item.label === checkItem)[0]
      //     )
      //   })
      //   return _.sortBy(finallyArray, (item) => item.order)
      // },
    },
    watch: {},
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, totalCount } = await postAction(
          '/depotAdmin/depot-stock/summary-depot-stock',
          this.form
        )
        this.columns = data.column
        this.total = totalCount
        this.list = data.list
        // this.totalAll = data.total
        this.loading = false
      },
      classChange(v) {
        this.form.class_id = v
      },
      brandChange(v) {
        this.form.brand_id = v
      },
      goodsSelect(v) {
        this.form.keyword = v
      },
      addRows(v) {
        this.form.keyword = v
      },
      handleCheck(row) {
        console.log('handleCheck', row)
        this.$refs.check.id = row.goods_id
        this.$refs.check.showDialog = true
      },
      handleQuery() {
        this.form.pageNo = 1
        this.fetchData()
      },
      handleExport() {
        downloadFile(
          '/depotAdmin/depot-stock/summary-depot-stock-export',
          '库车统计.xlsx',
          this.form
        )
      },
      handleCurrentChange(val) {
        this.form.pageNo = val
        this.fetchData()
      },
      handleSizeChange(val) {
        this.form.pageSize = val
        this.fetchData()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
